<route>
{
  "meta": {
    "permission": [
      "contenttypes.add_contenttype",
      "contenttypes.change_contenttype",
      "contenttypes.delete_contenttype",
      "contenttypes.view_contenttype"
    ]
  }
}
</route>

<template>
  <v-card class="px-2 my-3">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <i-toolbar
          :title="
            role.pk != undefined && role.pk != ''
              ? `${$t('edit')} ${$tc('roles_areas', 1)}`
              : `${$t('add', { value: $tc('roles_areas', 1) })}`
          "
          :loading="load"
        ></i-toolbar>
        <v-card-text>
          <v-row>
            <v-col v-for="area in areas" :key="area.pk" cols="12" md="6" lg="4">
              <h5 class="text-capitalize mb-0">{{ area.name }}</h5>
              <v-switch
                v-for="permission in area.areapermissions"
                :key="permission.key"
                :label="permission.name"
                :value="permission"
                v-model="areaPermissions"
              >
              </v-switch>
              <hr />
            </v-col>
          </v-row>
        </v-card-text>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      load: false,
      loading: false,
      areas: [],
      areaPermissions: [],
      role: {
        pk: '',
        name: '',
        permisions: []
      }
    }
  },
  methods: {
    getGroups() {
      this.loading = true
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.role = {
          pk: '',
          name: '',
          permisions: []
        }
      } else {
        this.$api.role.show({ pk: validation }).then((res) => {
          this.role = res.data
          this.areaPermissions = this.role.permissions
          this.$route.meta.title = this.$t('edit', { model: '' })
        })
      }
    },
    async submit() {
      var dato = {
        group_id: this.role.pk,
        permissions: this.areaPermissions
      }
      try {
        this.load = true
        this.role.pk === ''
        await this.$api.role.permission.edit({
          pk: this.role.pk,
          form: dato
        })
        this.getGroups()
        this.$toast.success(`${this.$tc('roles', 1)} ${this.$tc('edited', 2)}`)
        this.$router.push({ name: 'roles' })
      } finally {
        this.load = false
      }
    },
    getRoleAreas() {
      this.$api.role.areas
        .list({})
        .then((response) => {
          this.areas = response.data
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch('actionLoading')
          }
          this.loading = false
        })
    }
  },
  mounted() {
    this.getGroups()
    this.getRoleAreas()
  }
}
</script>
